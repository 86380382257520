<template>
  <div class="wrapper">
    <img alt="coregemlogo" src="@/assets/coregemlogo.jpeg" class="imagelogo"/>

    <div
      class="image"
      style="
        background-image: url(https://quikgem-repo.s3.amazonaws.com/bg.jpg);
      "
    >
      <div
        class="container"
        style="
          display: flex;
          justify-content: center;
          width: 100%;
          transform: translateY(-15%);
          align-item: center;
        "
      >
        <div class="container-text">
          <span class="core">Welcome to</span>
          <h2>
            COREGEM SYSTEMS <br />
            CORE BANKING <br />
            APPLICATION
          </h2>
          <hr
            style="
              width: 60px;
              height: 3px;
              background: #bfa161;
              transform: translateX(-260%);
            "
          />
          <p
            style="
              font-size: 14px;
              line-height: 24px;
              color: #ffff;
              width: 378px;
            "
          >
            Coregem is a multi-bank banking system, modularized with modern
            technology and architecture to power retail and digital banking
            offerings, comprising the core, the middle layer and the channels.
          </p>
        </div>
      </div>
    </div>

    <!-- Main Content -->
    <div
      class="container"
      style="width: 50%; display: flex; justify-content: left"
    >
      <div class="login_form text-center">
        <p class="m-3"> Login in to start your account</p>

        <form control="" @submit.prevent="onSubmit">
          <div class="form_div">
            <input type="text" name="Bank code" class="formInput" required v-model="model.bankCode" placeholder="" autocomplete="off" :rules="{required:true}">
            <i class="fa fa-university"></i>
            <label for="Bank Code" class="form_label">Enter Your Bank Code</label>
          </div>

          <div class="form_div">
            <input type="text" name="username" class="formInput" required v-model="model.username"  autocomplete="off" placeholder="" :rules="{ required: true }">
            <i class="fa fa-user"></i>
            <label for="Username" class="form_label">Enter Your Username</label>
          </div>

          <div class="form_div">
            <input type="password" class="formInput" name="Password" required v-model="model.password" autocomplete="off" placeholder="" :rules="{ required: true }">
            <i class="fa fa-lock"></i>
            <label for="password" class="form_label">Enter Your Password</label>
          </div>

          <span class="forgot_password_span"> Forgot Password ? </span>

          <div class="form_div">
            <button type="submit" class="bttn btn" :disabled="loading"> Login <span :class="{ 'spinner-border': loading }"></span></button>
          </div>


<!--          <div class="row">-->
<!--            <base-input-->
<!--              label="Bank Code"-->
<!--              class="form__input"-->
<!--              name="Bank Code"-->
<!--              :rules="{ required: true }"-->
<!--              append-icon="fa fa-university"-->
<!--              v-model="model.bankCode"-->
<!--              placeholder=""-->
<!--            >-->
<!--            </base-input>-->
<!--            <base-input-->
<!--              label="Username"-->
<!--              class="form__input"-->
<!--              name="Username"-->
<!--              placeholder=""-->
<!--              :rules="{ required: true }"-->
<!--              append-icon="ni ni-circle-08"-->
<!--              v-model="model.username"-->
<!--            >-->
<!--            </base-input>-->

<!--            <base-input-->
<!--              label="Password"-->
<!--              class="form__input"-->
<!--              name="Password"-->
<!--              :rules="{ required: true }"-->
<!--              append-icon="ni ni-lock-circle-open"-->
<!--              type="password"-->
<!--              v-model="model.password"-->
<!--              placeholder = ""-->
<!--            >-->
<!--            </base-input>-->
<!--          </div>-->
<!--          <div style="margin: 0; text-align: right">-->
<!--            <span-->
<!--              style="font-family: Roboto; font-size: 12px; line-height: 14px"-->
<!--              ><b>Forgot Password ?</b></span-->
<!--            >-->
<!--          </div>-->
<!--          <div class="row">-->
<!--            <base-button-->
<!--              block-->
<!--              type="success"-->
<!--              native-type="submit"-->
<!--              outline-->
<!--              :disabled="loading"-->
<!--              class="btn col-12"-->
<!--              >Submit <span :class="{ 'spinner-border': loading }"></span-->
<!--            ></base-button>-->
<!--          </div>-->
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    //BaseHeader
  },
  data() {
    return {
      model: {
        username: "",
        password: "",
        bankCode: "",
        rememberMe: false,
      },
      notifications: {
        topCenter: false,
      },
      //disableBtn:false,
      //spinner:false,
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmit() {
      this.$store.dispatch(
        "logon",
        {
          username: this.model.username,
          userBankCode: this.model.bankCode,
          userPassword: this.model.password,
          source: "WEB",
          userCountryCode: "234",
        },
        { root: false }
      );
      // this.disableBtn = true;
      //this.spinner = true;
      //this.showDismissibleAlert=true;
      // dummy delay
      /* setTimeout(() => {
        if(this.model.username==='123456'){
        this.disableBtn = !true;
    this.spinner = !true;
    }
      }, 20000);
*/
      // this.$router.push({ name: "Alternative" });
    },
 
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
      loading: (state) => state.auth.loading,
      success: (state) => state.auth.success,
    }),
    ...mapActions(["logon"]),
    ...mapGetters([""]),
  },
  watch: {
    errors(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.$toast.error(newValue);
        //this.showDismissibleAlert=true;
      }
    },
    success(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);

      // Do whatever makes sense now
      if (newValue !== null) {
        this.$toast.success(`Login ${newValue}ful`);
        //this.showDismissibleAlert=true;
      }
    },
  },
};
</script>
><style lang="css" scoped>
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
h2 {
  font-family: Roboto;
  font-weight: 900;
  font-style: normal;
  line-height: 42.19px;
  color: #ffffff;
  font-size: 36px;
}

.wrapper {
  display: flex;
  align-items: center;
  background:none !important;

}

.imagelogo {
  position: absolute;
  width: 285px;
  height: 75px;
  left: 75%;
  top: 5%;
}

.forgot_password_span{
  float: right;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  height: 14px
}

.image {
  height: 100vh;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.text-primary {
  color: #212529 !important;
}

.fa-android {
  font-size: 3em;
}
@media screen and (max-width: 640px) {
  .main-content {
    width: 90%;
  }
  .company__info {
    display: none;
  }
  .login_form {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
@media screen and (min-width: 642px) and (max-width: 800px) {
  .main-content {
    width: 70%;
  }
}

.login_form {
  background:none;
  position: relative;
  width: 20rem;
  top: -50px;
}

.fa{
  position: absolute;
  top: 20%;
  left: 90%;
  color: #000000;
}

.formInput{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border:2px solid #D6D6D6;
  border-radius: 6px;
  background: none;
  outline: none;
  padding: 10px;
}

.form_div{
  position: relative;
  height: 70px;
  margin-bottom: 5px;
}

.formInput:hover{
  border-color: #000000;
}

.formInput:focus{
  border-color: #000000;
}

.form_label{
  position: absolute;
  left: 1em;
  top:0.8rem;
  padding: 0 0.5rem;
  cursor: text;
  transistion:top 200ms ease-in,
              left 200ms ease-in,
              font-size 200ms ease-in,;
  background-color:#f2F4FF;
}

.formInput:focus ~ .form_label{
  top: -0.5rem;
  font-size: 0.8rem;
  left: 0.8rem;

}
.formInput:not(:placeholder-shown).formInput:not(:focus) ~.form_label{
  top: -0.5rem;
  font-size: 0.8rem;
  left: 0.8rem;
}

.bttn {
  transition: all 0.5s ease;
  width: 100%;
  border-radius: 30px;
  height: 45px;
  color: #ffff;
  font-weight: 600;
  background-color: #664f5f;
  margin-top: 0.5em;
  border: #664f5f;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
}
.btn:hover{
  background-color:#664f5f;
  color: #fff;
}


.core {
  font-weight: 100;
  font-size: 18px;
  line-height: 28px;
  color: #ffff;
}
</style>
